<template>
  <div class="effectPrediction">
    <div class="main box box_column">
      <div class="mainTop">
        <ul class="ul box center">
          <li v-for="(item, index) in list" :key="index" class="mR20 pointer" :class="active == item.id ? 'activeColor' : ''" @click="getTab(item)">{{ item.name }}</li>
        </ul>
      </div>
      <!-- <div>{{time[0]}}</div>
        <div>{{time[1]}}</div> -->
      <div class="mainBottom flex1">
        <div class="box center main1" style="width: 100%; height: 100%">
          <div class="unitDiv">
            <div class="d1">{{ typeName }}：{{ unit }}</div>
          </div>
          <div class="poa_center" v-show="!y1.length && !y2.length">
            <div class="noDataMain">
              <img src="../../../assets/images/noData.png" alt="" />
              <span>暂无数据</span>
            </div>
          </div>
          <div id="effectBox"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "effectPrediction",
  data() {
    return {
      projectId: "",
      active: "",
      list: [],

      time: [],
      size: 10000,
      typeName: "",
      time: [],
      y1: [],
      y2: [],
      unit: "",
      length: ""
    };
  },
  //创建
  created() {
    this.projectId = sessionStorage.getItem("projectId");
  },

  //安装
  mounted() {
    var x = [1, 2, 3, 4, 5, 6];
    var y1 = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6];
    var y2 = [1.1, 2.2, 3.3, 1.4, 2.5, 3.6];
    var showed = false;

    // 时间获取
    var day = new Date();
    var day1 = day.getTime(); //获得当前时间戳
    var day11 = day1 + 24 * 60 * 60 * 1000;
    var day2 = day1 - 30 * 24 * 60 * 60 * 1000;

    // this.time2 = day.getTime();                   //当前时间戳
    // this.time1 = day1 - 10*24 * 60 * 60 * 1000;  //10个小时的时间戳

    var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
    var end = moment(day11).format("YYYY-MM-DD HH:mm:ss");

    console.log(start, end);
    this.time = [];
    this.time.push(start);
    this.time.push(end);

    console.log("this.time1", this.time);
    this.listData(); //测点列表
  },

  //方法
  methods: {
    getTab(item) {
      console.log("item", item);
      if (this.active == item.id) {
        return;
      }

      this.active = item.id;
      this.unit = item.unit;
      this.typeName = item.typeName;

      // 时间获取
      var day = new Date();
      var day1 = day.getTime(); //获得当前时间戳
      var day11 = day1 + 24 * 60 * 60 * 1000;
      var day2 = day1 - 30 * 24 * 60 * 60 * 1000;

      // this.time2 = day.getTime();                   //当前时间戳
      // this.time1 = day1 - 10*24 * 60 * 60 * 1000;  //10个小时的时间戳

      var start = moment(day2).format("YYYY-MM-DD HH:mm:ss");
      var end = moment(day11).format("YYYY-MM-DD HH:mm:ss");

      console.log(start, end);
      this.time = [];
      this.time.push(start);
      this.time.push(end);

      this.offectData();
      this.historyData();
    },

    listData() {
      this.$axios.get(`${this.baseURL}offect/select/${this.projectId}`).then((res) => {
        console.log("测点列表", res.data);
        this.list = res.data.data;
        console.log("typename,this.list", this.list && this.list.length);
        if (this.list && this.list.length) {
          this.active = this.list[0]["id"];
          this.unit = this.list[0]["unit"];
          this.typeName = this.list[0]["typeName"];
          this.offectData();
          this.historyData();
        }
      });
    },
    // 效应预测
    offectData() {
      console.log("this.time2", this.time);
      // this.y2 = ['-2','-3','-2','-4','-2','-1','-2','-7','-2','-3'];
      this.$axios.get(`${this.baseURL}data/history/offect/${this.active}?sort=0&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`).then((res) => {
        console.log("效应数据-多", res.data.data);
        var data = res.data.data;
        this.y2 = [];
        data.forEach((item, index) => {
          this.y2.push(item.data[0]);
        });

        // console.log('y2-------------', this.y2);

        this.length = this.y2.length;
      });
    },
    // 历史监测值
    historyData() {
      console.log("this.time3", this.time);
      this.$axios.get(`${this.baseURL}data/history/raw/${this.active}?sort=0&startTime=${this.time[0]}&endTime=${this.time[1]}&size=${this.size}`).then((res) => {
        console.log("历史数据-少", res.data.data);
        var data = res.data.data;
        this.time = [];
        this.y1 = [];
        if (!data.length) {
          return false
        }
        data.forEach((item, index) => {
          this.time.push(item.time);
          this.y1.push(item.data[0]);
        });
        setTimeout(() => {
          // console.log('time', this.time);
          // console.log('y1', this.y1);
          // console.log('y2', this.y2);
          var y = this.y1.slice(-this.length);
          var time = this.time.slice(-this.length);
          // console.log('y', y);
          this.y2.unshift("-");
          y.push("-");

          console.log("time----------", this.time.slice(-1));

          // var time1 = time[this.length-1];
          // console.log('time1',time1);
          var time1 = this.time.slice(-1);
          var day = new Date(time1);
          console.log("day", day);
          var day1 = day.getTime(); //获得当前时间戳
          var day11 = day1 + 10 * 60 * 1000;
          var start = moment(day11).format("YYYY-MM-DD HH:mm:ss");
          console.log("start", start);
          time.push(start);

          // console.log('time', time.length);
          var y2 = this.y2.splice(-time.length);

          console.log("y--------------------------", y);
          console.log("y1--------------------------", y2);
          console.log("y1--------------------------", time);
          const timeArray = time.map(dateTimeString => {
            const momentObj = moment(dateTimeString).format("YYYY-MM-DD HH:mm:ss");
            return momentObj;
        });

          var showed = false;
          this.$chart1.line2("effectBox", timeArray, y, y2, "", showed); //y this.y2
        }, 300);
      });
    }
  }
};
</script>
<style scoped lang="scss">
.effectPrediction {
  width: 100%;
  height: 100%;
}

.main {
  width: 100%;
  height: 100%;
  background: rgba($color: #091d50, $alpha: 0.84);
  padding: 28px;
}

.ul li {
  padding: 6px 28px;
  border-radius: 20px;
  color: #6d81bf;
  background: #233361;
}

.mainBottom {
  padding: 49px 33px;
}

#effectBox {
  width: 100%;
  height: 100%;
}

.activeColor {
  color: #fff !important;
  background: #0050b4 !important;
}

//单位竖
.unitDiv {
  color: #4e5f8a;
  width: 22px;

  // position: relative;
  .d1 {
    transform: rotate(-90deg) translate(-20%, -50%);
    transform-origin: 0% 0%;
    position: absolute;
    left: 114px;
    // top: 50%;
    // left: 0;
    width: 150px;
  }
}

.main1 {
  background: #142654;
  border-radius: 24px;
  padding: 20px;
}
.poa_center{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
